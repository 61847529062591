// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:1371c010-9a2b-4aa4-93a3-3de043b0af52",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_v6U7G261y",
    "aws_user_pools_web_client_id": "518ohntamm2dv1faohgj3qlemq",
    "oauth": {},
    "aws_user_files_s3_bucket": "airportreports2-zxzwzxuzxuwxz152927-prod",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "airportreports2-20200420154404-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d3b4av0nltqvt0.cloudfront.net",
    "aws_mobile_analytics_app_id": "d2fb6feeaa054cb8ba959ee69c651197",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
