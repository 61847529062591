import React, {Component, useState, useEffect}  from 'react';
import { withAuthenticator } from 'aws-amplify-react';
import { Auth, Storage } from 'aws-amplify';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import './App.css';


function App() {
  const [fileLinks, setfileLinks] = useState([]);
  var filePairs = [];
  var tempString ='filevalue:';
  var login ='';
  var market = '';
  var Panels ='';
  var i=1;
  var bodyText =[];
  var str;
    

  useEffect(() => {
    async function fetchData() {
        try {                    
          var i =0;
          var cardHeader ='';             
          const user = await Auth.currentAuthenticatedUser();  //get user reference
          login = user.attributes["custom:Market"];
          var files = await Storage.list(''); //get S3 file list
          var filenames = files.map(f => (f.key)); //files is an array of objects so need to pull out file name
          // this retrieves files and signed URLs for S3 Files, needed do this here due to async issues
          filenames.forEach(f => {
              Storage.get(f, {expires: 300})
              .then(data => {
                filePairs.push('fileid:'+ f +'filevalue:'+ data);
              }) 
          }); 
          //outter loop sets accordion header, inner sets appropiate file and S3 link from previously
          //--- populated filePairs array.
          filenames.forEach(f => {
            if(user.attributes["custom:Market"]=="CCA"){
              Storage.get(f, {expires: 300})
              .then(data => {
                if(cardHeader != f.slice(0,3) ){
                  cardHeader = f.slice(0,3)
                  setfileLinks(fileLinks =>[...fileLinks,
                    <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey={i}>{f.slice(0,3)}</Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={i}>
                      <Card.Body>
                      {filePairs.forEach(f2 => {
                          if(cardHeader == f2.slice(7,10)){
                            bodyText.push(<li key={Math.random() * 100}><a href={f2.slice((f2.lastIndexOf(tempString))+10)}>
                              {f2.slice(7,(f2.indexOf(tempString)-4))}</a></li>)                         
                          }
                        })
                       }
                      {bodyText.map(item =>(item))}
                      {bodyText = []}
                      </Card.Body>
                    </Accordion.Collapse>
                    </Card>]);
                    i++;
                }
              })
            }
            else if(f && f.includes(user.attributes["custom:Market"])) { 
              Storage.get(f, {expires: 300})
              .then(data => {
                if(cardHeader != f.slice(0,3) ){
                  cardHeader = f.slice(0,3)
                  setfileLinks(fileLinks =>[...fileLinks,
                    <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey={i}>{f.slice(0,3)}</Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={i}>
                      <Card.Body>
                      {filePairs.forEach(f2 => {
                          if(cardHeader == f2.slice(7,10)){
                            bodyText.push(<li key={Math.random() * 100}><a href={f2.slice((f2.lastIndexOf(tempString))+10)}>
                              {f2.slice(7,(f2.indexOf(tempString)-4))}</a></li>)                         
                          }
                        })
                       }
                      {bodyText.map(item =>(item))}
                      {bodyText = []}
                      </Card.Body>
                    </Accordion.Collapse>
                    </Card>]);
                    i++;
                }    
              }) 
            }
          })
        } catch (e) {
            console.error(e);
        }
    };   
  
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
 
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

    return (
      <div className="App">        
        <p> </p>
        <p> </p>
        <br></br>

        <Accordion>
          {fileLinks.map(item =>(item))}
       </Accordion>
      
      </div>
    )
  

}

const MyTheme = 
{ //https://github.com/aws-amplify/amplify-js/blob/master/packages/aws-amplify-react/src/Amplify-UI/Amplify-UI-Theme.tsx
  //---- rememeber to use camel case for CSS styles
  sectionHeader: {marginTop: "20px", textAlign: "center", fontFamily: "Arial, Helvetica, sans-serif", fontWeight: "bold", fontSize: "20px"},
  sectionBody: {textAlign: "center", marginTop: "10px", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "15px"},
  hint: {visibility: "hidden"},
  sectionFooterSecondaryContent: {visibility: "hidden"},
  button: {marginLeft: "auto", marginRight: "auto", display: "block", backgroundColor: "lightgrey", borderColor: "lightgrey" }
};

//need to edit the greeting
//export default withAuthenticator(App, { includeGreetings: true, theme: MyTheme });
export default withAuthenticator(App, false, [], null, MyTheme);
//export default withAuthenticator(App);